.login-component-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-shadow-border {
  padding: 40px;
  border-radius: 10px;
  border: 1px solid gainsboro;
  box-shadow: 5px 5px 17px 9px #ebebeb;
}


.correct-rotate img {
  animation: rotation 49s ease-in-out infinite;
  transform: translateZ(0px);
  z-index: 2;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.card-container-row .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-container-row .ant-card {
  height: 100%;
}